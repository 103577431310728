.sharedCarousel {
  text-align: center;
}

.cardiv {
  padding-bottom: 10%;
}

.imgcaro {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .imgcaro {
    width: 110%;
  }
}
