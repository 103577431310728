.policybold {
  font-weight: bold;
}

.privacyhover:hover,
.privacyhover:focus {
  color: white;
  text-decoration: none;
}

.privacyhover {
  color: white;
  text-decoration: none;
}
