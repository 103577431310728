.whyustitle {
  font-size: 137%;
  padding-bottom: 2%;
  text-align: left;
  color: #fb6600;
}

.whyusmain {
  line-height: 281%;
  font-size: 112%;
  text-align: left;
  border: none;
}

@media only screen and (max-width: 600px) {
  .whyusmain {
    line-height: 281%;
    font-size: 112%;
    text-align: center;
    border: none;
  }

  .whyustitle {
    font-size: 137%;
    padding-bottom: 2%;
    text-align: center;
    color: #fb6600;
  }

  .buttoncenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding-bottom: 17%;
  }
}
